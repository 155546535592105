// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ErrorDisplay.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ErrorDisplay.tsx");
  import.meta.hot.lastModified = "1738830140000";
}
// REMIX HMR END

export const ErrorDisplay = props => {
  console.error(props.message, props.error);
  return <div className={`${props.className} tw-flex tw-flex-col tw-gap-2`}>
      <h2>
        Sorry, something went wrong.
      </h2>
      <span>
        Please try again or contact administrator if the problem persists.
      </span>
    </div>;
};
_c = ErrorDisplay;
var _c;
$RefreshReg$(_c, "ErrorDisplay");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;